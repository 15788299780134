import React from "react"
import Layout from "../../components/layout/Layout.js"
import { BlogRow, Row } from "../../components/row/Row.js"
import {
  BlogTitle,
  SubHeading,
} from "../../components/title/Title.js"
import { TextMedium } from "../../components/text/Text.js"
import { Column50 } from "../../components/column/Column.js"
import Seo from "../../components/seo"
import img1 from "../../static/saas-content-ideas.png"
import { Breadcrumb } from '../../components/breadcrumb/Breadcrumb'
import {
  Image,
} from "../../components/image/Image.js"
import { AboveFold, Section } from "../../components/section/Section.js"
import GumroadForm from "../../components/gumroadForm/GumroadForm.js"
import { ButtonContainer, Contact } from "../../components/button/Button.js"

function ContentIdeas({ children, ...rest }) {
  return (
    <Layout>
      <Seo title="180 Content Ideas for B2B SaaS Companies" 
       description="This document gives you content ideas for different content types, from blog articles, research reports and playbooks, to white papers, webinars, and opinion articles."
       image={img1}
       featuredImage={img1} />

      <AboveFold isLight>
        <Row>
        <Breadcrumb>
            <li>
              <a href="/content-strategy-templates/">All templates</a>
            </li>
            <li>
              <a href="/content-strategy-templates/saas-content-ideas/">180 Content Ideas for B2B SaaS Companies</a>
            </li>
          </Breadcrumb>
        </Row>
        <Row isCentered>
          <Column50>
            <BlogTitle isPrimary title="180 Content Ideas for B2B SaaS Companies" />
            <SubHeading subheading="This document gives you content ideas for different content types, from blog articles, research reports
         and playbooks, to white papers, webinars, and opinion articles." />
            <ButtonContainer>
              <Contact buttonText="Get the template" buttonLink="https://socontent.gumroad.com/l/saas-content-ideas" />
            </ButtonContainer>
          </Column50>
          <Column50>
            <Image src={img1} alt="180 Content Ideas for B2B SaaS Companies" />
          </Column50>
        </Row>
      </AboveFold>

      <Section>
        <BlogRow isCentered>
          <TextMedium
              textMedium="This document gives you content ideas for different content types, from blog articles, research reports and playbooks, to white papers, webinars, and opinion articles." /> 
          <TextMedium textMedium="You'll receive access to an Airtable base with content ideas grouped by content types, and labeled based on funnel stage and intent." /> 
          <GumroadForm 
                formName="SaaS Content Ideas"
                redirectPage="https://socontent.gumroad.com/l/saas-content-ideas"
                buttonText="Get it on Gumroad"
                buttonLink="https://socontent.gumroad.com/l/saas-content-ideas"
                // checkboxText="*Your consent is required. By checking this box, you agree to be contacted by us when we publish similar content." 
            />
        </BlogRow>
      </Section>
    </Layout>
  )
}

export default ContentIdeas
